// @flow

import React from 'react';
import formatDate from 'date-format';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Box } from '@material-ui/core';
import styles from './CampaignItem.module.scss';
import OptimizedAsset from '../OptimizedAsset';

const CampaignItem = ({ item, noDates = false }) => {
  const isExpired =
    item.endDate && new Date(item.endDate).getTime() < Date.now();

  return (
    <OutboundLink
      className={styles.campaignItem}
      href={item.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box className={styles.container}>
        {isExpired && (
          <div className={styles.overlayContainer}>
            <div className={styles.overlay} />
            <p className={styles.text}>終了しました</p>
          </div>
        )}
        <OptimizedAsset
          className={styles.cover}
          src={item.banner}
          alt="campaign-cover"
          loading="lazy"
        />
      </Box>
      {!noDates && (
        <p className={styles.date}>
          {formatDate('yyyy/MM/dd', new Date(item.startDate))} -
          {item.endDate &&
            ` ${formatDate('yyyy/MM/dd', new Date(item.endDate))}`}
        </p>
      )}
      <h3 className={styles.shopName}>{item.merchantName}</h3>
      <h3>{item.title}</h3>
    </OutboundLink>
  );
};

export default CampaignItem;
