// @flow
import React from 'react';
import cx from 'classnames';
import OptimizedAsset from '../OptimizedAsset';
import { gtag } from '../../utils';

import styles from './EditorPicks.module.scss';

const EditorPickItem = ({ pick, isHeading, isMobileView }) => {
  return (
    <div
      className={cx(
        styles.articleBox,
        isHeading && !isMobileView && styles.heading
      )}
    >
      <a
        className={cx(
          styles.imgWrapper,
          `ga_click_picup_button_${isHeading ? 'heroimage' : pick.shopName}`
        )}
        href={pick.url}
        title={pick.shopName}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => {
          gtag({
            event: 'user_action',
            ga_action: 'click_editorpicks',
            ga_label: pick.shopName,
          });
        }}
      >
        <OptimizedAsset
          src={pick.imageURL}
          alt={pick.title}
          width="100%"
          lazyLoad
        />
      </a>
      <div className={styles.info}>
        <h3>{pick.shopName}</h3>
        {pick.title && <h3>{pick.title}</h3>}
        <p>{pick.description}</p>
        {isHeading && !isMobileView && (
          <div className={styles.shopNowBtn}>
            <a
              className="btn gradient ga_click_picup_button_moreshop"
              href={pick.url}
              title={pick.shopName}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => {
                gtag({
                  event: 'user_action',
                  ga_action: 'click_editorpicks',
                  ga_label: pick.shopName,
                });
              }}
            >
              お店を見てみる
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditorPickItem;
